import * as React from 'react';

const ModalVideo = ({ nombreVideo, video, id }) => {

  console.log(nombreVideo, video)

  return (
    <div className="modal fade" id={`exampleModal${id}`} data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{nombreVideo}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="ratio ratio-16x9">
              <iframe src={video} title={nombreVideo} allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalVideo