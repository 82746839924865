import React from 'react'
import './App.css'
import Spinner from './utilidades/spinner/spinner'
import Carousel from 'react-elastic-carousel'
import ModalVideo from "./utilidades/modalVideo/modalVideo"
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

// import Acerca from './componentes/acerca/acerca'
// import Servicios from './componentes/servicios/servicios'
// import Conctacto from './componentes/conctacto/conctacto'
import Footer from './utilidades/footer/footer'
import WOW from "wowjs"
import Typed from 'react-typed'
const Menu = React.lazy(() => import('./utilidades/nav/nav'))
const Bugitech = React.lazy(() => import('./svgs/bugitech'))
const Acerca = React.lazy(() => import('./componentes/acerca/acerca'))
const Servicios = React.lazy(() => import('./componentes/servicios/servicios'))
const Conctacto = React.lazy(() => import('./componentes/conctacto/conctacto'))

export default class App extends React.Component {

  state = {
    items: [
      { title: "Palma tecnologia", img: require('./img/logos/hospital.jpg'), url: "https://www.youtube.com/embed/9uwTHvrx1vM" },
      { title: "Producciones SETA", img: require('./img/logos/LOGOnegreo.png') },
      { title: "VetkNina", img: require('./img/logos/cartaknina.png'), url: "https://www.youtube.com/embed/f_6npKPXqPs" },
      { title: "Solanco", img: require('./img/logos/solanco.png') },
      { title: "Athom Creation", img: require('./img/logos/actioncreation.png') },
      { title: "Abogados y asociados", img: require('./img/logos/abogadosasociados.png') },
      { title: "CleanLife", img: require('./img/logos/cleanlife.png') },
      { title: "Ambudog", img: require('./img/logos/ambudog.png') },
      { title: "Surtimotos del sur", img: require('./img/logos/surtimotosdelsur.jpg') },
    ],
    size: {
      scroll: (2000 < window.screen.width) ? 4 : (((1080 < window.screen.width) ? 3 : ((500 < window.screen.width) ? 2 : 1))),
      show: (2000 < window.screen.width) ? 5 : (((1080 < window.screen.width) ? 3 : ((500 < window.screen.width) ? 2 : 1)))
    }
  }

  componentDidMount() {
    new WOW.WOW().init();

  }
  render() {
    return (
      <div className="App" >

        <React.Suspense fallback={<Spinner />}>
          <div className="bg-inicio">
            <Menu />
            <div className="bg-tra-black" id="inicio">

              <Bugitech />
              <Typed
                typedRef={(typed) => { typed.start() }}
                strings={['<h1 class="text-center position-absolute title-bugi" style="left:0;right:0; top:80%">Bugitech</h1>']}
                typeSpeed={60}
              />
            </div>
          </div>
        </React.Suspense>

        <React.Suspense fallback={<Spinner />}>
          <Acerca />
        </React.Suspense>


        <React.Suspense fallback={<Spinner />}>
          <Servicios />
        </React.Suspense>

        <React.Suspense fallback={<Spinner />}>
          <div className="bg-acerca" id="clientes">
            <div className="bg-tra-primary py-5">
              <h1 className="text-center text-white my-5 font-weight-bold">Nuestros clientes y aliados</h1>
              <Carousel itemsToScroll={this.state.size.scroll} itemsToShow={this.state.size.show}>
                {this.state.items.map((item, i) => <div className="card mx-2 my-2 shadow wow animate__animated animate__fadeIn" key={i} >
                  <div className="card-body text-center">
                    <h3 className="title-bug-service text-center font-weight-bold">
                      {item.title}
                    </h3>
                    <img src={item.img} className="w-100" style={{ height: "40vh" }} alt={item.title} />
                    <br />
                    {item.url ?
                      <button type="button" className="btn btn-primary mt-2" data-bs-toggle="modal" data-bs-target={`#exampleModal${i}`}>
                        Ver video
                      </button>
                      : null}
                  </div>
                  <ModalVideo nombreVideo={item.title} video={item.url} id={i} />
                </div>)}
              </Carousel>
            </div>
          </div>


          <div className="bg-acerca" id="colaboramos">
            <div className="bg-tra-primary py-5">
              <h1 className="text-center text-white my-5 font-weight-bold">Colaboramos</h1>
              <div className="row">
                <div className="col-md-4  text-center m-auto">
                  <img
                    role="button"
                    src={require('./img/logos/pubdev.png')}
                    className="thumnail"
                    style={{ height: "40vh" }}
                    alt="pubdev"
                    onClick={() => window.location.href = "https://pubdev.ml/"}
                  />
                </div>
                <div className="col-md-8 p-5">
                  <p className="text-bug text-white">
                    En bugitech apoyamos y fomentamos iniciativas como PubDev una comunidad de desarrollo de software sin ánimo de lucro, en la cual se abren espacios colaborativos y creativos dónde estudiantes, graduados o personas interesadas en aprender a programar puedan de forma abierta y gratuita compartir información y conocimientos, para mantenerse actualizados y así tener un mayor crecimiento profesional y personal.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Conctacto />

          <Footer />
        </React.Suspense>

      </div>

    );
  }
}

