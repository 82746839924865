import React from 'react'
import "./footer.css"

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <p>&copy;Bugitech S.A.S  {new Date().getFullYear()}. Todos los derechos reservados</p>
      </div>
    )
  }
}