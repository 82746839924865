import * as React from 'react';
import './spinner.css'

const Spinner = () => {

    return (
        <div className="d-flex justify-content-center contenedor">
            <div className="spinner-grow text-primary styleSpinner py-6" role="status">
                <span className="visually-hidden"></span>
            </div>
        </div>
    )

}

export default Spinner